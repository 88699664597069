import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {setHours, areIntervalsOverlapping, format} from 'date-fns';
import {Form, withForm, WithFormProps, Button, Input, InformationTile, CustomDatePicker} from '@innowise-group/ui-kit';
import {FloorsSelect, OfficesSelect, RoomsSelect, WorkspacesSelect, RequestTypeSelect} from '@shared-components';
import {
  AvailableRoomsAndWorkspaces,
  Employee,
  EmployeesApiService,
  useService,
  WorkspaceBookType,
  WorkspacesApiService,
} from '@innowise-group/core';
import * as Styled from './booking-workspace-form.styles';
import RequestsApiService from '../../core/services/requests-api/requests-api.service';
import {finalize, forkJoin, map, Observable} from 'rxjs';
import {getItemById, validateDate} from '@innowise-group/utilities';
import {checkBookingDatesCollisions, findFirstAvailableDate, getDatesToExclude} from './utilities';

export enum BookingWorkspaceFormFields {
  Address = 'address',
  Floor = 'floor',
  Room = 'room',
  Seat = 'seat',
  DateRange = 'dateRange',
  Comment = 'comment',
  RequestType = 'requestType',
}

export enum RequestType {
  FullOccupation = 'Full occupation',
  TemporalOccupation = 'Temporal occupation',
}

interface BookingWorkspaceValuesRaw {
  address: string;
  floor: string;
  room: string;
  seat: string;
  dateRange: [Date, Date];
  comment: string;
  requestType: RequestType;
}

export type BookingWorkspaceValues = Omit<BookingWorkspaceValuesRaw, 'dateRange'> & {
  startDay: string;
  endDay: string;
};

export type ExcludeDates = Array<{
  employeeId: string;
  range: {start: Date; end: Date};
}>;

export type Range = {
  start: Date;
  end: Date;
};

export type WorkspacesBookingList = Array<{
  id: string;
  excludeDates: ExcludeDates;
  activeDates: ExcludeDates;
  status: WorkspaceBookType;
  room: {id: string; number: string};
  number: string;
}>;

export type BookingWorkspaceFormProps = {
  submitButton?: string;
  employee?: Employee;
  excludeDates?: ExcludeDates;
  workspacesList?: WorkspacesBookingList;
  warningModal?: (text: string, title: string, button: string) => void;
  confirmationModal?: (
    title: string,
    text: string,
    confirmationText: string,
    cancelText: string,
    onComplete?: () => void,
  ) => Observable<void>;
  withValidateBookedWorkspace?: boolean;
  editRequestId?: string;
};

const BookingWorkspaceForm: React.FC<WithFormProps<BookingWorkspaceValues> & BookingWorkspaceFormProps> = ({
  onSubmit,
  onCancel,
  initialValues = {},
  submitButton,
  employee,
  isSubmit,
  isWatchFieldChanged,
  setIsWatchFieldChanged,
  workspacesList = [],
  confirmationModal,
  withValidateBookedWorkspace = false,
  editRequestId,
}) => {
  const {t} = useTranslation();
  const {
    handleSubmit,
    control,
    formState: {errors, isValid},
    resetField,
    setValue,
    setError,
    clearErrors,
    watch,
  } = useForm({
    mode: 'onChange',
  });

  const {address, floor, room, seat, startDay, endDay, comment} = initialValues;
  const requestsApi = useService(RequestsApiService);
  const [isFormSubmitted, setIsFormSubmitted] = useState<boolean>(false);
  const [availableWorkspaces, setAvailableWorkspaces] = useState<AvailableRoomsAndWorkspaces[]>([]);

  const addressRef = useRef(address);
  const floorRef = useRef(floor);
  const roomRef = useRef(room);
  const workspacesApi = useService(WorkspacesApiService);
  const employeesApi = useService(EmployeesApiService);

  const watchStartDay = watch(BookingWorkspaceFormFields.DateRange)?.[0] ?? startDay;
  const watchEndDay = watch(BookingWorkspaceFormFields.DateRange)?.[1] ?? endDay;
  const excludedDates = useMemo(
    () =>
      getDatesToExclude(workspacesList, watch(BookingWorkspaceFormFields.Seat, seat), editRequestId, startDay, endDay),
    [workspacesList, watch, seat, editRequestId, startDay, endDay],
  );
  const availableDate = useMemo(() => findFirstAvailableDate(excludedDates), [excludedDates]);

  useEffect(() => {
    setIsWatchFieldChanged({
      [BookingWorkspaceFormFields.Floor]: false,
      [BookingWorkspaceFormFields.Room]: false,
      [BookingWorkspaceFormFields.Seat]: false,
    });
  }, [setIsWatchFieldChanged]);

  useEffect(() => {
    if (watchStartDay && watchEndDay) {
      workspacesApi
        .getAvailableRoomsAndWorkspaces(
          floor,
          format(new Date(watchStartDay), 'yyyy-MM-dd'),
          format(new Date(watchEndDay), 'yyyy-MM-dd'),
        )
        .subscribe(setAvailableWorkspaces);
    } else {
      workspacesApi
        .getAvailableRoomsAndWorkspaces(floor, format(availableDate, 'yyyy-MM-dd'), format(availableDate, 'yyyy-MM-dd'))
        .subscribe(setAvailableWorkspaces);
    }
  }, [
    availableDate,
    editRequestId,
    endDay,
    floor,
    initialValues,
    startDay,
    watch,
    watchEndDay,
    watchStartDay,
    workspacesApi,
  ]);

  useEffect(() => {
    const subscription = watch((values: BookingWorkspaceValuesRaw, {type}) => {
      if (type) {
        if (addressRef.current !== values.address) {
          resetField(BookingWorkspaceFormFields.Floor);
          resetField(BookingWorkspaceFormFields.Room);
          resetField(BookingWorkspaceFormFields.Seat);
          addressRef.current = values.address;
          setIsWatchFieldChanged({
            [BookingWorkspaceFormFields.Floor]: true,
            [BookingWorkspaceFormFields.Room]: true,
            [BookingWorkspaceFormFields.Seat]: true,
          });
        } else if (floorRef.current !== values.floor) {
          resetField(BookingWorkspaceFormFields.Room);
          resetField(BookingWorkspaceFormFields.Seat);
          floorRef.current = values.floor;
          setIsWatchFieldChanged({
            [BookingWorkspaceFormFields.Room]: true,
            [BookingWorkspaceFormFields.Seat]: true,
          });
        } else if (roomRef.current !== values.room) {
          resetField(BookingWorkspaceFormFields.Seat);
          roomRef.current = values.room;
          setIsWatchFieldChanged({
            [BookingWorkspaceFormFields.Seat]: true,
          });
        } else {
          setIsWatchFieldChanged({
            [BookingWorkspaceFormFields.Floor]: false,
            [BookingWorkspaceFormFields.Room]: false,
            [BookingWorkspaceFormFields.Seat]: false,
          });
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, resetField, setIsWatchFieldChanged]);

  const getWorkspaceById = (id: string) => getItemById(workspacesList, id);

  const getRequestsData = useCallback(
    (values: BookingWorkspaceValues) => {
      return requestsApi.getWorkspacesRequestsList(null, address).pipe(
        map((requests) =>
          requests.filter((request) => {
            return editRequestId
              ? request.user.id === employee.id &&
                  (request.status === 'approved' || request.status === 'in-clarification') &&
                  request.id !== editRequestId &&
                  request.office.id === values.address
              : request.user.id === employee.id &&
                  (request.status === 'approved' || request.status === 'in-clarification');
          }),
        ),
      );
    },
    [address, editRequestId, employee, requestsApi],
  );

  const onSubmitHandler = useCallback(
    (rawValues: BookingWorkspaceValuesRaw) => {
      const {dateRange} = rawValues;
      const values = {
        ...rawValues,
        startDay: dateRange?.[0]?.toString() ?? null,
        endDay: dateRange?.[1]?.toString() ?? null,
      };

      setIsFormSubmitted(true);

      if (!withValidateBookedWorkspace) {
        return onSubmit(values);
      }

      forkJoin([getRequestsData(values), employeesApi.getEmployeeById(employee.id)])
        .pipe(finalize(() => setIsFormSubmitted(false)))
        .subscribe(([requestsData, employeeData]) => {
          const permanentWorkspace = employeeData?.workspaces.find(
            (workspace) =>
              workspace.status === 'Occupied' &&
              (workspace.officeId === values.address || values.requestType === RequestType.FullOccupation),
          );

          if (permanentWorkspace) {
            const modalValues = {
              workspaceNumber: permanentWorkspace.number,
              roomNumber: permanentWorkspace.room,
              floorNumber: permanentWorkspace.floor,
              officeName: `${permanentWorkspace?.city}, ${permanentWorkspace?.address}`,
            };

            return confirmationModal(
              t('modals.attention'),
              t('modals.employeePermanentlyOccupiedWorkspaceMessage', modalValues),
              t('buttons.yes'),
              t('buttons.no'),
              () => {
                setIsFormSubmitted(false);
              },
            ).subscribe(() => onSubmit(values));
          }

          const selectedDates = {
            start: setHours(new Date(values.startDay), 9),
            end: setHours(new Date(values.endDay), 18),
          };

          const requestOverlap = requestsData.find(
            ({range}) => range.start && range.end && areIntervalsOverlapping(selectedDates, range),
          );

          if (requestOverlap) {
            const modalValues = {
              workspaceNumber: requestOverlap.seat.number,
              roomNumber: requestOverlap.room.number,
              floorNumber: requestOverlap.floor.number,
              officeName: requestOverlap.office.address,
              requestStartDate: format(requestOverlap.range.start, 'dd.MM.yyyy'),
              requestEndDate: format(requestOverlap.range.end, 'dd.MM.yyyy'),
            };
            confirmationModal(
              t('modals.attention'),
              requestOverlap.status === 'in-clarification'
                ? t('modals.hasTemporaryWorkspaceOnThisDateMessage', modalValues)
                : t('modals.hasTemporaryBookedWorkspaceOnThisDateMessage', modalValues),
              t('buttons.yes'),
              t('buttons.no'),
              () => {
                setIsFormSubmitted(false);
              },
            ).subscribe(() =>
              requestsApi.workspaceCanceledRequest(requestOverlap.id).subscribe(() => onSubmit(values)),
            );
          } else {
            onSubmit(values);
          }
        });
    },
    [withValidateBookedWorkspace, getRequestsData, requestsApi, employee, onSubmit, confirmationModal, t, employeesApi],
  );

  return (
    <Form.Container onSubmit={handleSubmit(onSubmitHandler)}>
      <Form.Layout layoutType="vertical">
        <Styled.EmployeeInfoContainerForm>
          <Styled.EmployeePhoto size={40} imageUrl={employee?.photoUrl} />
          <InformationTile title={employee?.fullName} value={employee?.department} />
        </Styled.EmployeeInfoContainerForm>
        <Form.Layout layoutType="grid" inset="top">
          <Controller
            name={BookingWorkspaceFormFields.Address}
            control={control}
            defaultValue={address}
            rules={{required: {value: true, message: t('modals.messages.required')}}}
            render={({field: {onChange, value}}) => (
              <Form.Field label={`${t('modals.office')} *`} error={errors[BookingWorkspaceFormFields.Address]?.message}>
                <OfficesSelect value={value} onValueChange={onChange} defaultValue={address} disabled />
              </Form.Field>
            )}
          />

          <Controller
            name={BookingWorkspaceFormFields.Floor}
            control={control}
            defaultValue={floor}
            rules={{required: {value: true, message: t('modals.messages.required')}}}
            render={({field: {onChange, value}}) => (
              <Form.Field label={`${t('modals.floor')} *`} error={errors[BookingWorkspaceFormFields.Floor]?.message}>
                <FloorsSelect
                  officeId={watch(BookingWorkspaceFormFields.Address, address)}
                  value={value}
                  onValueChange={onChange}
                  defaultValue={floor}
                  isFieldChanged={isWatchFieldChanged[BookingWorkspaceFormFields.Floor]}
                  disabled
                />
              </Form.Field>
            )}
          />
        </Form.Layout>
        <Form.Layout layoutType="grid" inset="top">
          <Controller
            name={BookingWorkspaceFormFields.Room}
            control={control}
            defaultValue={room}
            rules={{required: {value: true, message: t('modals.messages.required')}}}
            render={({field: {onChange, value}}) => (
              <Form.Field label={`${t('modals.room')} *`} error={errors[BookingWorkspaceFormFields.Room]?.message}>
                <RoomsSelect
                  value={value}
                  floorsId={watch(BookingWorkspaceFormFields.Floor, floor)}
                  onValueChange={onChange}
                  defaultValue={room}
                  isFieldChanged={isWatchFieldChanged[BookingWorkspaceFormFields.Room]}
                  position="fixed"
                  roomsList={availableWorkspaces}
                />
              </Form.Field>
            )}
          />
          <Controller
            name={BookingWorkspaceFormFields.Seat}
            control={control}
            defaultValue={seat}
            rules={{required: {value: true, message: t('modals.messages.required')}}}
            render={({field: {onChange, value}}) => (
              <Form.Field label={`${t('modals.seat')} *`} error={errors[BookingWorkspaceFormFields.Seat]?.message}>
                <WorkspacesSelect
                  value={value}
                  roomId={watch(BookingWorkspaceFormFields.Room, room)}
                  onValueChange={onChange}
                  defaultValue={seat}
                  isFieldChanged={isWatchFieldChanged[BookingWorkspaceFormFields.Seat]}
                  position="fixed"
                  startDay={watch(BookingWorkspaceFormFields.DateRange)?.[0]}
                  endDay={watch(BookingWorkspaceFormFields.DateRange)?.[1]}
                  editRequestId={editRequestId}
                  roomsList={availableWorkspaces}
                />
              </Form.Field>
            )}
          />
        </Form.Layout>
        <Form.Layout layoutType="grid" inset="top">
          <Controller
            name={BookingWorkspaceFormFields.DateRange}
            control={control}
            defaultValue={[startDay ? new Date(startDay) : availableDate, endDay ? new Date(endDay) : availableDate]}
            rules={{
              validate: (value) =>
                watch(BookingWorkspaceFormFields.RequestType) !== RequestType.TemporalOccupation ||
                value?.every(Boolean) ||
                t('modals.messages.required'),
            }}
            render={({field: {onChange, value}}) => (
              <Form.Field
                label={`${t('modals.bookingStart')} / ${t('modals.bookingEnd')}${
                  watch(BookingWorkspaceFormFields.RequestType) === RequestType.TemporalOccupation ? ' *' : ''
                }`}
                error={errors[BookingWorkspaceFormFields.DateRange]?.message}
              >
                <CustomDatePicker
                  disabled={watch(BookingWorkspaceFormFields.RequestType) !== RequestType.TemporalOccupation}
                  selectsRange
                  selected={
                    watch(BookingWorkspaceFormFields.DateRange)?.[0]
                      ? watch(BookingWorkspaceFormFields.DateRange)?.[0]
                      : availableDate
                  }
                  startDate={watch(BookingWorkspaceFormFields.DateRange)?.[0]}
                  endDate={watch(BookingWorkspaceFormFields.DateRange)?.[1]}
                  minDate={new Date()}
                  maxDate={validateDate.maxInRange(watch(BookingWorkspaceFormFields.DateRange))}
                  dateFormat="dd/MM/yyyy"
                  placeholderText={t('placeholders.datePicker')}
                  excludeDateIntervals={excludedDates}
                  isClearable
                  onChange={(value: Date | [Date, Date]) => {
                    if (!Array.isArray(value)) return;
                    const end = value[1];
                    if (
                      end &&
                      checkBookingDatesCollisions(
                        watch(BookingWorkspaceFormFields.Seat),
                        workspacesList,
                        value[0],
                        end,
                        employee.id,
                      )
                    ) {
                      setValue(BookingWorkspaceFormFields.DateRange, [new Date(), null]);
                      setError(BookingWorkspaceFormFields.DateRange, {
                        type: 'custom',
                        message: t('modals.messages.datesOverlap'),
                      });
                    } else {
                      clearErrors(BookingWorkspaceFormFields.DateRange);
                      onChange(value);
                    }
                  }}
                />
              </Form.Field>
            )}
          />
          <Controller
            name={BookingWorkspaceFormFields.RequestType}
            control={control}
            defaultValue={RequestType.TemporalOccupation}
            rules={{
              validate: (requestType: RequestType) => {
                if (requestType === RequestType.FullOccupation) {
                  const {activeDates, excludeDates} = getWorkspaceById(watch(BookingWorkspaceFormFields.Seat));
                  return activeDates.length || excludeDates.length ? t('modals.messages.datesOverlap') : true;
                }
                return true;
              },
            }}
            render={({field: {onChange, value}}) => (
              <Form.Field
                label={`${t('modals.requestType')} *`}
                error={errors[BookingWorkspaceFormFields.RequestType]?.message}
              >
                <RequestTypeSelect
                  onValueChange={onChange}
                  value={value}
                  defaultValue={RequestType.TemporalOccupation}
                />
              </Form.Field>
            )}
          />
        </Form.Layout>
        <Form.Layout layoutType="vertical" inset="top">
          <Controller
            name={BookingWorkspaceFormFields.Comment}
            control={control}
            defaultValue={comment}
            render={({field: {onChange, value}}) => (
              <Form.Field label={t('modals.comment')} error={errors[BookingWorkspaceFormFields.Comment]}>
                <Input
                  multiline
                  fullWidth
                  maxLength={96}
                  defaultValue={value}
                  onChange={onChange}
                  placeholder={t('placeholders.comment')}
                />
              </Form.Field>
            )}
          />
        </Form.Layout>
      </Form.Layout>
      <Form.Actions>
        <Button type="submit" disabled={isSubmit || isFormSubmitted || !isValid} withLoader={isSubmit}>
          {submitButton}
        </Button>
        <Button type={'button'} variant="outlined" onClick={onCancel}>
          {t('buttons.cancel')}
        </Button>
      </Form.Actions>
    </Form.Container>
  );
};

export default withForm(BookingWorkspaceForm);
